<template>
    <v-menu
        ref="validityStartDatemenu"
        v-model="menu"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        :max-width="maxWidth"
        :min-width="minWidth"
    >
        <template v-slot:activator="{ on, attrs }">
        <v-text-field
            v-model="displayDateForText"
            :label="label"
            :clearable="clearable"
            :prepend-icon="prependIcon"
            v-bind="attrs"
            v-on="on"
            :rules="rules"
            @click:clear="displayDateForText = null"
            :disabled="disabled"
            :hint="hint"
            :persistent-hint="persistentHint"
        ></v-text-field>
        </template>
        <v-date-picker
            v-model="displayDateForPicker"
            no-title
            @input="menu = false"
            :allowed-dates="allowedDates"
            :min="computedMinDate"
            :max="computedMaxDate"
        ></v-date-picker>
    </v-menu>
</template>
<script>

import { DateTime } from 'luxon';

export default {
    name: 'DateInputComponent',
    data: function() {
        return {
            menu: false,
        };
    },
    props: {
        rules: {
            type: Array,
            default() {
                return [];
            }
        },
        label: {
            type: String,
            default: ''
        },
        maxWidth: {
            type: String,
            required: true
        },
        minWidth: {
            type: String,
            required: true
        },
        prependIcon: String,
        appendIcon: String,
        valueDateTime: {
            type: Object,
            default: null
        },
        refId: String,
        displayFormat: {
            type: String,
            default: 'yyyy-MM-dd'
        },
        clearable: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        hint: {
            type: String,
            default: undefined
        },
        persistentHint: {
            type: Boolean,
            default: false
        },
        allowedDates: Function,
        min: Object,
        max: Object
    },
    computed: {
        displayDateForText: {
          get: function() {
            if (this.valueDateTime) {
              let parsedDate =  DateTime.fromObject(this.valueDateTime)
              if (parsedDate.invalid == null) {
                return parsedDate.toFormat(this.displayFormat);
              }
            }
            return null;
          },
          set: function(val) {
            if(val) {
              let parsedDate = DateTime.fromFormat(val, this.displayFormat);
              if (parsedDate.invalid == null) {
                this.$emit('update:valueDateTime', {day: parsedDate.day, month: parsedDate.month, year: parsedDate.year});
              } else {
                this.$emit('update:valueDateTime', null);
              }
            } else {
              this.$emit('update:valueDateTime', null);
            }
          }
        },
        displayDateForPicker: {
          get: function() {
            if(this.valueDateTime) {
              let parsedDate =  DateTime.fromObject(this.valueDateTime)
              if (parsedDate.invalid == null) {
                return parsedDate.toFormat('yyyy-MM-dd');
              }
            }
            return null;
          },
          set: function(val) {
            if(val) {
              let parsedDate = DateTime.fromFormat(val, 'yyyy-MM-dd');
              if (parsedDate.invalid == null) {
                this.$emit('update:valueDateTime', {day: parsedDate.day, month: parsedDate.month, year: parsedDate.year});
              } else {
                this.$emit('update:valueDateTime', null);
              }
            } else {
              this.$emit('update:valueDateTime', null);
            }
              
          }
        },
        computedMinDate() {
          if(this.min) {
            let parsedDate =  DateTime.fromObject(this.min)
              if (parsedDate.invalid == null) {
                return parsedDate.toFormat('yyyy-MM-dd');
              } else {
                return null;
              } 
          } else {
            return null;
          }
        },
        computedMaxDate() {
          if(this.max) {
          let parsedDate =  DateTime.fromObject(this.max)
            if (parsedDate.invalid == null) {
              return parsedDate.toFormat('yyyy-MM-dd');
            } else {
              return null;
            } 
          } else {
            return null;
          }
        }
    }
}
</script>
