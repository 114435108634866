import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMenu,{ref:"validityStartDatemenu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":_vm.maxWidth,"min-width":_vm.minWidth},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({attrs:{"label":_vm.label,"clearable":_vm.clearable,"prepend-icon":_vm.prependIcon,"rules":_vm.rules,"disabled":_vm.disabled,"hint":_vm.hint,"persistent-hint":_vm.persistentHint},on:{"click:clear":function($event){_vm.displayDateForText = null}},model:{value:(_vm.displayDateForText),callback:function ($$v) {_vm.displayDateForText=$$v},expression:"displayDateForText"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c(VDatePicker,{attrs:{"no-title":"","allowed-dates":_vm.allowedDates,"min":_vm.computedMinDate,"max":_vm.computedMaxDate},on:{"input":function($event){_vm.menu = false}},model:{value:(_vm.displayDateForPicker),callback:function ($$v) {_vm.displayDateForPicker=$$v},expression:"displayDateForPicker"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }